<template>
  <div>
    <Menu/>
    <FeaturedProperty />
    <section>
      <div class="container" v-if="!loading">
        <div class="row justify-content-center">
          <div class="col-lg-7 text-center mb-3">
            <h1 class="mb-3">FIND A PROPERTY</h1>
            <p>This immaculate collection houses some of South Africa’s most prestigious properties. Explore exquisite coastal estates, majestic safari lodges, enchanting vineyard retreats, sprawling farm manors and more. From breathtaking landscapes to unrivalled craftmanship, this portfolio is the epitome of luxury living.</p>
          </div>
        </div>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-8 pt-5 text-center">
              <h3>Choose a price range</h3>
              <p>
                <span class="mx-2">R 10 million</span>
                <span class="mx-2">to</span>
                <span class="mx-2">R {{ maxPrice / 1000000 }} million</span>
                <input
                  type="range"
                  class="form-range"
                  v-model="maxPrice"
                  :min="12000000"
                  :max="150000000"
                  :step="1000000"
                  @input="filterListings"
                />
              </p>
              <button class="btn btn-primary filter-btn" :class="{ 'active': highToLow }" v-on:click="highToLow = true" style="font-size: 10px;">display high to low</button>
              <button class="btn btn-primary filter-btn" :class="{ 'active': !highToLow }" v-on:click="highToLow = false" style="font-size: 10px;">display low to high</button>
            </div>
          </div>
          <div class="row">
            <center><h3 class="pt-5 pb-2">Choose your lifestyle</h3></center>
            <div class="col-12 text-center">
              <button class="btn btn-primary filter-btn filterOne" :class="{ 'active': selectedType === 'buy' }" 
                @click="toggleType('buy')">Buy</button>
              <button class="btn btn-primary filter-btn filterOne" :class="{ 'active': selectedType === 'rent' }"
                @click="toggleType('rent')">Rent</button>
              <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('inland') }"
                @click="toggleLocation('inland')">Inland</button>
              <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('coastal') }"
                @click="toggleLocation('coastal')">Coastal</button>
              <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('countryside') }"
                @click="toggleLocation('countryside')">Countryside</button>
              <button class="btn btn-primary filter-btn filterTwo" :class="{ 'active': selectedLocations.includes('city') }"
                @click="toggleLocation('city')">City</button>
            </div>
          </div>
        </div>
        
        <div class="row justify-content-center">
            <p class="pt-5">
              <center><strong>Showing:</strong> {{ filteredListings.length }} properties</center>
            </p>
            <a id="top"></a>
            <center><h3 class="pt-1 pb-2">Search for a location</h3></center>
            <div class="col-md-4 text-center">
                <!-- Existing location buttons -->
                <input
                    type="text"
                    class="form-control mb-3"
                    placeholder="Enter a location here"
                    v-model="locationSearch"
                    @input="handleLocationSearch"
                />
                <ul v-if="locationSuggestions.length > 0 && locationSearch != ''" class="list-group">
                    <li
                        v-for="suggestion in locationSuggestions"
                        :key="suggestion"
                        class="list-group-item"
                        @click="selectSuggestion(suggestion)"
                    >
                        {{ suggestion }}
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="row justify-content-center">
          <div v-if="paginatedListingsFiltered.length == 0">
            <h1 class="pt-5">No properties match your criteria</h1>
          </div>
          <div class="col-lg-4 col-md-6 mt-5 mb-4 pb-4"
            v-for="(item, index) in paginatedListingsFiltered"
          >
            <div class="banner-section lifestyle-block switch-bg-lgrey p-3 h-100 bord-radius">
              <div class="portfolio-thumbnail">
                <a :href="'/property/' + item.payload.listing.data.listing[0].id"><img :src="item.payload.listing.featured_image" class="w-100 force-height"></a>
                <div class="thumbnail-priceTag">
                  {{ formatCurrency(item.payload.listing.data.listing[0].price) }}
                </div>
                <div class="portfolio-type">
                  <div class="typeOne mx-1" v-for="(tag, propertyName) in item.payload.listing.tags" v-if="tag == true">{{ propertyName }}</div>
                </div>
              </div>
              <h4 class="text-start">
                <span>{{ item.payload.listing.data.listing[0].loc_suburb }}, {{ item.payload.listing.data.listing[0].loc_region }}</span>
                {{ item.payload.listing.data.listing[0].marketing_heading }}
              </h4>
            </div>
            <a class="social-links switch-bg-dblue mb-0 mx-3 cta-link" :href="'/property/' + item.payload.listing.data.listing[0].id">&nbsp; <strong style="color: #fff;">FIND OUT MORE</strong> &nbsp;</a>
          </div>
        </div>

        <div class="pagination-controls mt-5" v-if="paginatedListingsFiltered != 0">
          <center>
            <button class="btn btn-primary" href="#top" @click="prevPage" :disabled="currentPage === 1"><a href="#top" style="color: #fff; text-decoration: none;"><i class="bi bi-arrow-left"></i> PREV</a></button>
            <button class="btn btn-primary" href="#top" @click="nextPage" :disabled="currentPage === totalPages"><a href="#top" style="color: #fff; text-decoration: none;">NEXT <i class="bi bi-arrow-right"></i></a></button>
          </center>
        </div>

      </div>
      <div v-else>
        <center><div class="spinner"></div></center>
        <h1>Loading luxury properties</h1>
      </div>
    </section>
    <Footer/>
  </div>
</template>

<script>
import axios from 'axios'
import Menu from '@/components/home/Menu.vue'
import Footer from '@/components/home/Footer.vue'
import FeaturedProperty from '@/components/home/FeaturedProperty.vue'

export default {
  components: {
    Menu, Footer, FeaturedProperty
  },
  data() {
    return {
      listings: [],
      loading: true,
      selectedType: 'buy',
      selectedLocations: [],
      minPrice: 10000000,
      maxPrice: 150000000,
      originalListings: [],
      currentPage: 1,
      itemsPerPage: 9,
      locationSearch: '',
      navigationArray: [],
      highToLow: true,
      fusionListings: []
    }
  },
  mounted() {
    this.fetchListings()
    if (this.$store.state.search.tags.max_price !== 0) {
      this.maxPrice = this.$store.state.search.tags.max_price
    }
    if (this.$store.state.search.tags.buy == true) {
      this.toggleType('buy');
    }
    if (this.$store.state.search.tags.rent == true) {
      this.toggleType('rent');
    }
    if (this.$store.state.search.tags.inland == true) {
      this.toggleLocation('inland');
    }
    if (this.$store.state.search.tags.coastal == true) {
      this.toggleLocation('coastal');
    }
    if (this.$store.state.search.tags.countryside == true) {
      this.toggleLocation('countryside');
    }
    if (this.$store.state.search.tags.city == true) {
      this.toggleLocation('city');
    }
    if (this.$store.state.search.tags.commercial == true) {
      this.toggleLocation('commercial');
    }
  },
  watch: {
    '$store.state.search.tags.buy'(newValue) {
      this.toggleType('buy');
    },
    '$store.state.search.tags.rent'(newValue) {
      this.toggleType('rent');
    },
    '$store.state.search.tags.inland'(newValue) {
      this.toggleLocation('inland');
    },
    '$store.state.search.tags.coastal'(newValue) {
      this.toggleLocation('coastal');
    },
    '$store.state.search.tags.countryside'(newValue) {
      this.toggleLocation('countryside');
    },
    '$store.state.search.tags.city'(newValue) {
      this.toggleLocation('city');
    },
    '$store.state.search.tags.commercial'(newValue) {
      this.toggleLocation('commercial');
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.filteredListings.length / this.itemsPerPage);
    },
    paginatedListingsFiltered() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredListings.slice(startIndex, endIndex);
    },
    filteredListings() {
      let filteredListings = this.originalListings.filter(listing => {
        // // Find the corresponding fusion listing
        // const fusionListing = this.fusionListings.find(fusion => fusion[0].id == listing.ref_id);

        // // Skip if no corresponding fusion listing is found or status is not active
        // if (!fusionListing || fusionListing[0].status !== 'Active') {
        //   console.log(`Skipping listing with ref_id: ${listing.ref_id} (Status: ${fusionListing ? fusionListing[0].status : 'Not Found'})`);
        //   return false;
        // }

        // console.log(`Including listing with ref_id: ${listing.ref_id} (Status: ${fusionListing[0].status})`);

        // Apply other filters
        const listingTags = listing.payload.listing.tags;
        const typeFilter = !this.selectedType || listingTags[this.selectedType];
        const locationFilter =
          this.selectedLocations.length === 0 ||
          this.selectedLocations.every(loc => listingTags[loc]);
        const locationSearchFilter = this.locationSearch
          ? `${listing.payload.listing.data.listing[0].loc_suburb}, ${listing.payload.listing.data.listing[0].loc_region}`
              .toLowerCase()
              .includes(this.locationSearch.toLowerCase())
          : true;
        const minPriceFilter = listing.payload.listing.data.listing[0].price >= this.minPrice;
        const maxPriceFilter = listing.payload.listing.data.listing[0].price <= this.maxPrice;
        const hasFeaturedImage = listing.payload.listing.featured_image; // Check if the listing has a featured image

        return (
          typeFilter &&
          locationFilter &&
          locationSearchFilter &&
          minPriceFilter &&
          maxPriceFilter &&
          hasFeaturedImage
        );
      });

      if (this.highToLow) {
        filteredListings = filteredListings.sort(
          (a, b) => b.payload.listing.data.listing[0].price - a.payload.listing.data.listing[0].price
        );
      } else {
        filteredListings = filteredListings.sort(
          (a, b) => a.payload.listing.data.listing[0].price - b.payload.listing.data.listing[0].price
        );
      }

      return filteredListings;
    },

    filteredLocations() {
      return this.locations.filter((location) =>
        location.toLowerCase().includes(this.searchText.toLowerCase())
      );
    },
    locationSuggestions() {
      const uniqueLocations = Array.from(
        new Set(
          this.originalListings.map(
            item =>
              `${item.payload.listing.data.listing[0].loc_suburb}, ${item.payload.listing.data.listing[0].loc_region}`
          )
        )
      );
      return uniqueLocations.filter(location =>
        location.toLowerCase().includes(this.locationSearch.toLowerCase())
      );
    },
  },
  methods: {
    handleLocationSearch() {

    },
    selectSuggestion(suggestion) {
        this.locationSearch = suggestion;
    },
    selectLocationSuggestion(suggestion) {
      this.locationSearch = suggestion;
      this.selectedLocations.push(suggestion);
      this.filterListings();
    },
    fetchListings: async function () {
      this.loading = true
      try {
        const response = await axios.get('https://luxury-dev.chaseveritt.co.za/api/fetch_listings.php', {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.originalListings = response.data
        this.listings = this.originalListings

        for (let i = 0; i < this.listings.length; i++) {
          if (this.listings[i].payload.listing.tags.buy == false && this.listings[i].payload.listing.tags.rent == false && this.listings[i].payload.listing.exclusion == '') {
            // console.log(this.listings[i].payload.listing.data.listing[0].id)
          }
        }

      } catch (error) {
        console.log(error);
      }

      // try {
      //   const response = await axios.get('https://luxury-dev.chaseveritt.co.za/api/fetch_listings_fusion.php', {
      //     headers: {
      //       'Accept': 'application/json',
      //     }
      //   });
      //   this.fusionListings = response.data

      // } catch (error) {
      //   console.log(error);
      // }

      this.originalListings = this.listings.filter(listing => {
        return listing.payload && listing.payload.listing && listing.payload.listing.exclusion === '' && !listing.payload.listing.tags.commercial;
      });

      this.navigationArray = this.originalListings.map((item, index, array) => {
        return {
          currentId: item.payload.listing.data.listing[0].id,
          nextId: (index < array.length - 1) ? array[index + 1].payload.listing.data.listing[0].id : null,
          lastId: (index > 0) ? array[index - 1].payload.listing.data.listing[0].id : null
        };
      });

      localStorage.setItem('setNavigationButtons', JSON.stringify(this.navigationArray))
      this.loading = false

    },
    toggleType(type) {
      this.selectedType = this.selectedType === type ? null : type
      this.filterListings()
    },
    toggleLocation(location) {
      const index = this.selectedLocations.indexOf(location)
      if (index !== -1) {
        this.selectedLocations.splice(index, 1)
      } else {
        this.selectedLocations.push(location)
      }
      this.filterListings()
    },
    filterListings() {
      if (
        this.selectedType ||
        this.selectedLocations.length > 0 ||
        this.minPrice > 0 ||
        this.maxPrice < 150000000 ||
        this.locationSearch !== ''
      ) {
        this.currentPage = 1;
        this.listings = this.filteredListings;
      } else {
        this.currentPage = 1;
        this.listings = this.originalListings;
      }
      this.navigationArray = this.listings.map((item, index, array) => {
        return {
          currentId: item.payload.listing.data.listing[0].id,
          nextId: (index < array.length - 1) ? array[index + 1].payload.listing.data.listing[0].id : null,
          lastId: (index > 0) ? array[index - 1].payload.listing.data.listing[0].id : null
        };
      });
      localStorage.setItem('setNavigationButtons', JSON.stringify(this.navigationArray))
      this.loading = false
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
      }
    },
  }
}
</script>

<style scoped>
.force-height {
  height: 300px;
  object-fit: cover;
}

.social-links{
  position: unset;
  display: inline;
  float: right;
  margin-right: 20px;
}
/* Styling the spinner */
.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left: 8px solid #3498db; /* Blue border */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.2s linear infinite; /* Spin animation */
}

/* Animation keyframes */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

